import { Level } from "../types/level.enum";

export class TopicOverview {
  constructor(
    public topicID: string,
    public image: string,
    public title: string,
    public summary: string,
    public level: Level
  ) {}
}
