import { Component, OnInit } from "@angular/core";
import { NbMenuItem, NbMenuService, NbThemeService } from "@nebular/theme";
import { DeviceDetectorService } from "ngx-device-detector";
import { filter } from "rxjs/operators";
import { SidenavService } from "./services/sidenav.service";
import { Themes } from "./types/themes.enum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  links: NbMenuItem[] = [
    { title: "Home", icon: "home-outline", link: "/home" },
    { title: "Blogs", icon: "book-outline", link: "/blogs" },
    { title: "Ressources", icon: "archive-outline", link: "/ressources" },
    { title: "About", icon: "at-outline", link: "/about" },
  ];

  toggleAnimate = false;

  constructor(
    public themeService: NbThemeService,
    public sidenavService: SidenavService,
    private menuService: NbMenuService,
    public deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.menuService
      .onItemClick()
      .pipe(filter((menu) => menu.tag === "sidebar-menu"))
      .subscribe((_) => this.sidenavService.toggle());
  }

  get themes() {
    return Themes;
  }

  changeThemeTo(theme: Themes): void {
    this.themeService.changeTheme(Themes[theme]);
  }

  toggleLeftSidebar(): void {
    this.sidenavService.toggle();
  }
}
