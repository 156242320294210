import { NgModule } from '@angular/core';
import {
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbMenuModule,
  NbContextMenuModule,
  NbAccordionModule,
  NbListModule,
  NbSpinnerModule,
} from '@nebular/theme';

const modules = [
  NbCardModule,
  NbButtonModule,
  NbIconModule,
  NbMenuModule,
  NbContextMenuModule,
  NbAccordionModule,
  NbListModule,
  NbSpinnerModule,
];

@NgModule({
  imports: modules,
  exports: modules,
})
export class NebulaModule {}
