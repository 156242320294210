import { Injectable } from "@angular/core";
import { Observable, empty, of } from "rxjs";
import { APIService } from "./api.service";
import { filter, map, catchError } from "rxjs/operators";
import { Level } from "../types/level.enum";
import { Topic } from "../models/topic";
import { TopicOverview } from "../models/topic-overview";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class BlogService implements Resolve<any> {
  constructor(private apiService: APIService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.apiService.fetchBlogs();
  }

  getTopicOverviewsByLevel(
    blogUUID: string,
    level: Level
  ): Observable<TopicOverview[]> {
    return this.apiService.blogs$.pipe(
      map(blogs =>
        blogs
          .find(b => b._id === blogUUID)
          .topicOverviews.filter(tO => tO.level === level)
      )
    );
  }
}
