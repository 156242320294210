import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.css']
})
export class ContentCardComponent implements OnInit {
  @Input()
  headerSize = 'x-large';
  @Input()
  title: string | TemplateRef<void>;
  @Input()
  footer: TemplateRef<void>;

  constructor() {}

  ngOnInit() {}

  isTemplateRef(attr: any): boolean {
    return attr instanceof TemplateRef;
  }
}
