import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-entry',
  templateUrl: './list-entry.component.html',
  styleUrls: ['./list-entry.component.css'],
})
export class ListEntryComponent {
  @Input()
  imageURL: string;
  @Input()
  title: string;
  @Input()
  summary: string;

  constructor() {}
}
