import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Language } from '../interfaces/language';
import { LanguageEN } from '../classes/language-en';
import { LanguageDE } from '../classes/language-de';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private language: BehaviorSubject<Language> = new BehaviorSubject<Language>(
    null
  );
  private en: Language = new LanguageEN();
  private de: Language = new LanguageDE();

  constructor() {
    this.setInitialLanguage();
  }

  switchToEn(): void {
    this.language.next(this.en);
  }
  switchToDe(): void {
    this.language.next(this.de);
  }
  translate(): Language {
    return this.language.value;
  }

  setInitialLanguage(): void {
    if (navigator.language.includes('de')) {
      this.language.next(this.de);
    } else if (navigator.language.includes('en')) {
      this.language.next(this.en);
    } else {
      this.language.next(this.en);
    }
  }

  getCurrentLanguageString(): string {
    switch (true) {
      case this.language.value instanceof LanguageDE:
        return 'de';
      case this.language.value instanceof LanguageEN:
        return 'en';
      default:
        return 'en';
    }
  }
}
