import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Language } from "../interfaces/language";
import { LanguageEN } from "../classes/language-en";
import { LanguageDE } from "../classes/language-de";
import { NbSidebarService } from "@nebular/theme";

@Injectable({
  providedIn: "root"
})
export class SidenavService {
  isOpen = true;
  automaticallyChanged = false;

  constructor(private sidebarService: NbSidebarService) {}

  toggle(): void {
    // this.sidebarService.toggle(false, "left");
    this.isOpen = !this.isOpen;
  }

  isDesktop(): boolean {
    return window.innerWidth >= 1392;
  }

  getOpenState(): boolean {
    if (this.isDesktop() && !this.isOpen) {
      this.isOpen = true;
      this.automaticallyChanged = false;
    } else if (!this.automaticallyChanged && !this.isDesktop() && this.isOpen) {
      this.isOpen = false;
      this.automaticallyChanged = true;
    }

    return this.isOpen;
  }
}
