import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const modules = [MatSidenavModule, MatProgressSpinnerModule];

@NgModule({
  imports: modules,
  exports: modules,
})
export class MaterialModule {}
