import { NgModule } from '@angular/core';
import { AnimatedIconComponent } from './animated-icon/animated-icon.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NebulaModule } from '../nebula.module';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { PlaceholderCardComponent } from './placeholder-card/placeholder-card.component';
import { MaterialModule } from '../material.module';
import { ListEntryComponent } from './list-entry/list-entry.component';
import { GoBackComponent } from './go-back/go-back.component';

const comps = [
  AnimatedIconComponent,
  ThemeSwitcherComponent,
  LanguageSwitcherComponent,
  ContentCardComponent,
  PlaceholderCardComponent,
  ListEntryComponent,
  GoBackComponent,
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NebulaModule, MaterialModule],
  declarations: comps,
  exports: comps,
})
export class SharedComponentsModule {}
