import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService, NbThemeService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';
import { Themes } from 'src/app/types/themes.enum';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss']
})
export class ThemeSwitcherComponent implements OnInit, OnDestroy {
  menuThemes: NbMenuItem[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private menuService: NbMenuService,
    public themeService: NbThemeService,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.initializeThemes();

    this.subscriptions.push(
      this.menuService
        .onItemClick()
        .pipe(
          filter(item => item.tag === 'theme-menu'),
          map(item => item.item.data)
        )
        .subscribe((theme: Themes) => this.changeThemeTo(theme))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  initializeThemes(): void {
    Object.values(Themes).forEach(theme => {
      this.menuThemes.push({
        title: this.languageService.translate()[theme],
        data: Themes[theme]
      });
    });
  }

  changeThemeTo(theme: Themes): void {
    this.themeService.changeTheme(Themes[theme]);
  }
}
