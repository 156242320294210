import { Language } from '../interfaces/language';

export class LanguageEN implements Language {
  trueDark = 'True Dark';
  dark = 'Dark';
  cosmic = 'Cosmic';
  corporate = 'Corporate';
  default = 'Default';
  de = 'German';
  en = 'English';
  blogsTitle = 'All the Blogs!';
  homeTitle = '_Welcome to blog.kounex.com!';
  aboutBodyTitle = 'Whatever-Stack ~';
  ressourcesSummary =
    'All kind of ressources which could be useful for people out there can be found here listed by topic (currently only Flutter)';
  ressourceFlutterSummary =
    "Flutter is Google's UI framework to enable multi-platform app development. It's possible to compile and run apps for mobile (iOS, Android), desktop (Linux, macOS, Windows) and even for the Web - using the same codebase!";
  presentationsExplanation =
    'For the summer semester 2020 at the HAW Hamburg I was able to offer a flutter course. Together with the students, we went through fundamental Dart and Flutter topics. As a guideline I created presentations that I used for the respective lecture part to bring the framework closer to the students.\n\nI don\'t want to keep these slides from you and so there are now German and English versions of them available in several file formats! Accompanying the course, I developed the repository <a href="https://github.com/Kounex/flutter_companion" target="_blank"> Flutter Companion </a> to convey the content in a practical way.';
  presentationsIntro =
    'First, I explain the basics of the course and Dart / Flutter and also the history of flutter. Later I will give tips for initial setup and use.';
  presentationsBasics =
    'Useful all-rounder tips paired with the most important features / differences that Dart comes with (compared to Java).';
  presentationsFirstSteps = 'The first steps in Flutter - incentives and meta information to start and write your first code.';
  presentationsAnimations =
    'Almost every user application uses animations, either for user experience or just as an eye catcher - the basics are here.';
  presentationsNavigators = 'We already have a Navigator through the MaterialApp widget but what if you need it customised?';
  presentationsDependencyInjection = 'As soon as an app grows, certain topics are almost inevitable...';
  presentationsTesting = 'Many developers hate this topic but it is mandatory in real projects.';
}
