import { Language } from '../interfaces/language';

export class LanguageDE implements Language {
  trueDark = 'True Dark';
  dark = 'Dark';
  cosmic = 'Cosmic';
  corporate = 'Corporate';
  default = 'Default';
  de = 'Deutsch';
  en = 'Englisch';
  blogsTitle = 'Alle verfügbaren Blogs!';
  homeTitle = '_Willkommen auf blog.kounex.com!';
  aboutBodyTitle = 'Whatever-Stack ~';
  ressourcesSummary =
    'Jegliche Art von Ressource, die einen Mehrwert bieten kann, findet ihr diese hier nach Thema aufgelistet (momentan nur Flutter)';
  ressourceFlutterSummary =
    "Flutter ist Google's UI framework mit dem Multi-Plattform Enwticklung ermöglicht wird. Es ist möglich Apps für Mobile (iOS, Android), Desktop (Linux, macOS, Windows) und fürs Web zu kompilieren und zu starten - und das mit einer Codebasis!";
  presentationsExplanation =
    'Zum Sommersemester 2020 an der HAW Hamburg wurde es mir ermöglicht, einen Flutter Kurs anzubieten. Zusammen mit den Studenten sind wir fundamentale Themen zu Dart und Flutter durchgegangen. Als Leitfaden habe ich Präsentationen erstellt, die ich für den jeweiligen Vorlesungsteil verwendet habe, um den Studenten das Framework näher zu bringen.\n\nDiese Folien will ich euch nicht vorenthalten und so gibt es diese nun in deutscher und englischer Ausführung in mehreren Dateiformaten! Begleitend zum Kurs habe ich das Repository <a href="https://github.com/Kounex/flutter_companion" target="_blank">Flutter Companion</a> erarbeitet, um den Inhalt auch praktisch zu vermitteln.';
  presentationsIntro =
    'Zunächst erläutere ich Grundlagen zum Kurs und zu Dart / Flutter und auch den Werdegang von Flutter. Später gebe ich Tipps für die initiale Einrichtung und Verwendung.';
  presentationsBasics =
    'Nützliche Allrounder-Tipps gepaart mit den wichtigsten Features / Unterschieden die Dart mit sich bringt (im Vergleich zu Java).';
  presentationsFirstSteps = 'Die ersten Schritte in Flutter - Anreize und Meta Informationen um zu starten und ersten Code zu schreiben.';
  presentationsAnimations =
    'Nahezu jede Anwender-Applikation verwendet Animationen, sei es für die User Experience oder einfach fürs Auge - die Basics dazu gibts hier.';
  presentationsNavigators = 'Durch das MaterialApp Widget haben wir bereits einen Navigator, aber was wenn man es custom braucht?';
  presentationsDependencyInjection = 'Sobald eine App größer wird, sind gewisse Themen nahe unausweichlich...';
  presentationsTesting = 'Viele Entwickler hassen dieses Thema, aber doch ist es gerade in realen Projekten nicht wegzudenken.';
}
