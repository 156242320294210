import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
  @Input()
  size = 'small';

  menuLangs: NbMenuItem[] = [{ title: this.languageService.translate().de }, { title: this.languageService.translate().en }];

  subscriptions: Subscription[] = [];

  constructor(private menuService: NbMenuService, public languageService: LanguageService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.menuService
        .onItemClick()
        .pipe(filter((item) => item.tag === 'lang-menu'))
        .subscribe((item) => {
          item.item.title === this.languageService.translate().de ? this.languageService.switchToDe() : this.languageService.switchToEn();
          this.setMenuLangs();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setMenuLangs(): void {
    this.menuLangs = [{ title: this.languageService.translate().de }, { title: this.languageService.translate().en }];
  }
}
