import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbLayoutModule, NbMenuModule, NbSidebarModule, NbThemeModule } from '@nebular/theme';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { NebulaModule } from './nebula.module';
import { LanguageService } from './services/language.service';
import { SidenavService } from './services/sidenav.service';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { HomeComponent } from './views/home/home.component';
import { APIService } from './services/api.service';
import { BlogService } from './services/blog.service';
import { AboutComponent } from './views/about/about.component';
import { RessourcesComponent } from './views/ressources/ressources.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NbThemeModule.forRoot({ name: 'trueDark' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NebulaModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    SharedComponentsModule,
    DeviceDetectorModule.forRoot(),
    FontAwesomeModule,
  ],
  declarations: [AppComponent, HomeComponent, AboutComponent],
  providers: [LanguageService, SidenavService, APIService, BlogService],
  bootstrap: [AppComponent],
})
export class AppModule {}
