import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-placeholder-card",
  templateUrl: "./placeholder-card.component.html",
  styleUrls: ["./placeholder-card.component.css"]
})
export class PlaceholderCardComponent {
  dots = ".";

  constructor() {
    setInterval(() => {
      if (this.dots.length < 3) {
        this.dots += ".";
      } else {
        this.dots = ".";
      }
    }, 500);
  }
}
