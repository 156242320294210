import { Level } from "./../types/level.enum";

export class Topic {
  constructor(
    public _id: string,
    public image: string,
    public title: string,
    public summary: string,
    public level: Level,
    public contentInMarkdown: string
  ) {}
}
