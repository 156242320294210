import { TopicOverview } from "./topic-overview";

export class Blog {
  constructor(
    public _id: string,
    public title: string,
    public summary: string,
    public topicOverviews: TopicOverview[]
  ) {}
}
